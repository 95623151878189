/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import './styles/app.css';

global.axios = require('axios').default;

import * as bootstrap from 'bootstrap';
global.bootstrap = bootstrap;

const mfrom4post = document.getElementsByClassName("mpost");

Object.keys(mfrom4post).forEach(function(key) {
    mfrom4post[key].addEventListener('submit',function (event) {
        event.preventDefault();
        if(typeof this.dataset.before !== "undefined")
        {
            window[ this.dataset.before ]();
        }
        var functionafter = false;
        if(typeof this.dataset.after !== "undefined")
        {
            functionafter = this.dataset.after;
//            console.log('call after ', functionafter);
        }
        let curl = this.getAttribute('action');
        let  inputElements = this.getElementsByTagName("input"),
            jsonObject = {};
        for(let i = 0; i < inputElements.length; i++){

            if(inputElements[i].type =='checkbox')
                jsonObject[inputElements[i].name] = inputElements[i].checked ;
            else
            if(inputElements[i].type =='radio'){
                if(inputElements[i].checked)
                    jsonObject[inputElements[i].name] = inputElements[i].value ;
            } else
            {
                jsonObject[inputElements[i].name] = inputElements[i].value;
            }

        }
        inputElements = this.getElementsByTagName("select");
        for(let i = 0; i < inputElements.length; i++){

            jsonObject[inputElements[i].name] =  inputElements[i].value;

        }
        inputElements = this.getElementsByTagName("textarea");
        for(let i = 0; i < inputElements.length; i++){
            jsonObject[inputElements[i].name] =  inputElements[i].value;
        }

        const sbtn = this.querySelector('button[type="submit"]');
        sbtn.setAttribute("disabled", "disabled");
        sbtn.blur();
        const prevtext = sbtn.textContent;
        sbtn.textContent = 'loading... ';
        const alist = this.getElementsByClassName("mwarns");
        if(alist.length>0)
        {
            alist[0].classList.add("d-none");
            alist[0].classList.remove("alert-warning", "alert-danger");
        }

        axios.post(curl, jsonObject)
            .then(response => {

                if(response.data.status>0){
                    if(alist.length>0) {
                        const warnspan = alist[0].querySelector('span');
                        warnspan.innerHTML = response.data.message;
                        alist[0].classList.remove("d-none");
                        alist[0].classList.add("alert-warning");
                    }
                } else  {
                    if(alist.length>0) {
                        const warnspan = alist[0].querySelector('span');
                        warnspan.innerHTML = response.data.message;
                        alist[0].classList.remove("d-none");
                        alist[0].classList.add("alert-success");
                    }
                    if(typeof response.data.redirect !=='undefined')
                        window.location.href =  response.data.redirect;

                }

                if(typeof response.data.method !== "undefined" && functionafter ){
                    window[ functionafter ](response.data);
                }
            })
            .catch(error => {

                if(alist.length>0) {
                    const warnspan = alist[0].querySelector('span');
                    warnspan.innerHTML = 'Application error';
                    alist[0].classList.remove("d-none");
                    alist[0].classList.add("alert-danger");
                }
            })
            .then(response => {

                setTimeout(function(){
                    sbtn.removeAttribute('disabled');
                    sbtn.textContent = prevtext;
                }, 800);

            });

    });
});

const mform2 = document.getElementsByClassName("m-form");
Object.keys(mform2).forEach(function (key) {
    mform2[key].addEventListener('submit',function (event) {
        event.preventDefault();
        this.querySelector('button[type="submit"]').classList.add('d-none');
        this.querySelector("#lspinner").classList.remove('d-none');
        this.submit();
    });

});

window.myModal = new bootstrap.Modal(document.getElementById('globeSpinner'));

const mform3 = document.getElementsByClassName("mspinform");
Object.keys(mform3).forEach(function (key) {
   mform3[key].addEventListener('submit',function (event) {
       event.preventDefault();
       myModal.show();
       this.submit();
   })
});

const delayedforms = document.getElementsByClassName("md-link");
Object.keys(delayedforms).forEach(function (key) {
    delayedforms[key].addEventListener('submit',function (event) {
        event.preventDefault();
        window.myModal.show();
        window.location.href =  this.getAttribute('action');
    })
});



const clowarn = document.getElementsByClassName("m-cross");
Object.keys(clowarn).forEach(function (key){
    clowarn[key].addEventListener('click',function (  ){
        this.parentNode.classList.add('d-none');
    });
});


const mpos = document.querySelectorAll(".m-popover");
Object.keys(mpos).forEach(function (midx) {
    mpos[midx].addEventListener('beforetoggle',(event)=>{
        if (event.newState === "open") {
            const mpr = mpos[midx].parentElement.getBoundingClientRect() ;
            const rcon = parseInt( window.innerWidth - mpr.right );

            mpos[midx].style.width = 'max('+ parseInt(mpr.width) +'px, 300px )';

            if( window.innerWidth < 676 ) {
                mpos[midx].style.left = '1px';
                mpos[midx].style.setProperty('--m-max-width',window.innerWidth-2 +'px' );
            } else {
                if(rcon > mpr.width){
                    mpos[midx].style.left = parseInt( mpr.left )  +'px';
                }   else  {
                    mpos[midx].style.right =  rcon  +'px';
                }
            }
            mpos[midx].style.top = parseInt( mpr.bottom ) + 2 +'px';
        }
    })
});

console.log('compiled 1.29');
